/* Header.module.css */

.headerCard {
  height: 340px;
  border-radius: 0;
}

.bannerImage {
  object-fit: cover;
  height: 100%;
}

.overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.overlayContent {
  height: 100%;
}

.logo {
  width: 110px;
  height: 110px;
  margin: 0 auto;
}

.restaurantName {
  text-align: center;
}

.openNowButton {
  border-radius: 0;
}

.checkIcon {
  margin-left: 5px;
}

.location {
  text-align: center;
  font-weight: bold;
}

.locationIcon {
  margin-right: 5px;
}